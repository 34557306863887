<template>
  <div>
    <ds-header title="服务手册查看"></ds-header>
    <a-form-model
      ref="ruleForm"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
    >
      <a-form-model-item label="目录名称">
        {{ form.title }}
      </a-form-model-item>
      <a-form-model-item label="所属目录">
        {{ form.belongTitle }}
      </a-form-model-item>
      <a-form-model-item label="优先级">
        {{ form.sortNum }}
      </a-form-model-item>
      <a-form-model-item label="当前所在手册">
        {{ form.handBookName }}
      </a-form-model-item>
      <a-form-model-item label="内容">
        <a-table :pagination="false" pagind :columns="columns" :data-source="data" bordered>
          <div slot="content" slot-scope="text">
            <div class="clearfix">
              <a-upload
                action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                list-type="picture-card"
                :showUploadList="{ showRemoveIcon: false }"
                :file-list="[{
                  uid: '-1',
                  name: 'image.png',
                  status: 'done',
                  url: text,
                }]"
              >
              </a-upload>
              <a-modal :visible="previewVisible" :footer="null"  @cancel="handleCancel">
                <img alt="example" style="width: 100%" :src="previewImage" />
              </a-modal>
            </div>
          </div>
        </a-table>
      </a-form-model-item>
      <a-form-model-item label="" v-bind="tailFormItemLayout">
       <a-button type="primary" @click="goBack">返回</a-button>
      </a-form-model-item>
    </a-form-model>
  </div>
</template>
<script>
import { findOneServiceManualById } from '@/api/serviceManualManagement'
const columns = [
    {
      title: '排序',
      dataIndex: 'sortNum',
      key: 'sortNum',
      width: 80,
      align: 'center'
    },
    {
      title: '内容',
      dataIndex: 'content',
      width: 80,
      align: 'center',
      scopedSlots: { customRender: 'content' },
    }
  ]
export default {
  name: 'contentConfig',
  data() {
    return {
      form: {},
      previewVisible: false,
      labelCol: { span: 2 },
      wrapperCol: { span: 10 },
      tailFormItemLayout: {
        wrapperCol: {
          xs: {
            span: 18,
            offset: 0,
          },
          sm: {
            span: 10,
            offset: 6,
          },
        },
      },
      columns,
      data: []
    }
  },
  async created() {
    const id = this.$route.query.id
    await this.findOne(id)
  },
  methods: {
    async findOne(id) {
      const res = await findOneServiceManualById(id)
      const { code, data } = res
      if (code === '200') {
        this.form = data
        this.data = data.contents
      }
    },
    goBack() {
      this.$router.back()
    },
    // 预览
    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }
      this.previewImage = file.url || file.preview;
      this.previewVisible = true;
    },
    // 取消预览
    handleCancel() {
      this.previewVisible = false;
    },
  }
}
</script>
<style scoped lang="scss">

</style>
